import { useSubscription }     from "@apollo/client";
import { useQuery }            from "@apollo/client";
import { useMutation }         from "@apollo/client";
import { gql }                 from "@apollo/client";
import { Field }               from "@relcu/final-form";
import { FormSpy }             from "@relcu/final-form";
import { Checkbox }            from "@relcu/rc";
import { Form }                from "@relcu/rc";
import { useNavigate }         from "@relcu/react-router";
import { pick }                from "@relcu/ui";
import { useThrottleCallback } from "@relcu/ui";
import { AvatarSizes }         from "@relcu/ui";
import { Avatar }              from "@relcu/ui";
import { Input }               from "@relcu/ui";
import { classNames }          from "@relcu/ui";
import { FontIcon }            from "@relcu/ui";
import { Keys }                        from "@relcu/ui";
import { EmptyList }                   from "@relcu/ui";
import { Box }                         from "@relcu/ui";
import { useSource }                   from "@relcu/ui";
import { JsonViewProps }               from "@relcu/ui";
import { DateTime }                    from "luxon";
import { useCallback }                 from "react";
import { useMemo }                     from "react";
import React                           from "react";
import { FC }                          from "react";
import { SubscriptionEvent }           from "../../../../types/graphql-global-types";
import { usePermissions }              from "../../../AccessControl";
import { useSchemaField }              from "../../useSchemaField";
import { GetLastNotesVariables }       from "./__types__/GetLastNotes";
import { GetLastNotes }                from "./__types__/GetLastNotes";
import { SendNewNoteVariables }        from "./__types__/SendNewNote";
import { SendNewNote }                 from "./__types__/SendNewNote";
import { SubscribeLastNotesVariables } from "./__types__/SubscribeLastNotes";
import { SubscribeLastNotes }          from "./__types__/SubscribeLastNotes";
import "./moom-loa-checklist-view.css";
import { MoomLoaChecklistClasses }     from "./MoomLoaChecklistClasses";

export const MoomLoaChecklistView: FC<JsonViewProps> = React.memo(function MoomLoaChecklistView(props) {
  const { $object: node, $viewer } = useSource();
  const { options: loaCashOutReasonOptions } = useSchemaField("Lead", "loaCashOutReason");
  const { options: loaVaStatementOptions } = useSchemaField("Lead", "loaVaStatement");
  const { options: loaElseOnBalancesOptions } = useSchemaField("Lead", "loaElseOnBalances");
  const { options: loaEmploymentStatusOptions } = useSchemaField("Lead", "loaEmploymentStatus");

  const [note, setNote] = React.useState("");
  const { canUpdate } = usePermissions(node);
  const navigate = useNavigate();
  const {
    data: { notes: { edges = [] } = {} } = {},
    refetch
  } = useQuery<GetLastNotes, GetLastNotesVariables>(GET_LAST_NOTES, {
    variables: { id: node.id },
    fetchPolicy: "no-cache"
  });
  const [send, { loading }] = useMutation<SendNewNote, SendNewNoteVariables>(SEND_NEW_NOTE, {
    update(cache, { data: { createNote: { note: node } } }) {
      setNote("");
    }
  });

  useSubscription<SubscribeLastNotes, SubscribeLastNotesVariables>(SUBSCRIBE_LAST_NOTES, {
    variables: {
      link: node.id
    },
    onData({ client, data: { data: { notes: { event } } } }) {
      switch (event) {
        case SubscriptionEvent.CREATE:
          refetch();
      }
    }
  });
  const data = useMemo(() => {
    return edges?.reverse();
  }, [edges]);

  const onSend = useCallback(async () => {
    try {
      await send({
        variables: {
          input: {
            subject: {
              link: node.id
            },
            owner: {
              link: $viewer.id
            },
            text: `LOA Note: ${note}`
          }
        }
      });
    } catch (e) {
      console.error(e);
    }
  }, [send, note]);
  const handleSubmit = (data) => {};

  const [update] = useMutation(UPDATE_LEAD);
  const saveLead = useThrottleCallback((data)=>{
    const { objectId, ...rest } = data;
    update({
      variables: {
        input: {
          id: objectId,
          fields: rest
        }
      }
    });
  },2000,false);
  const handleOnChange = ({ values: formValues }) => {
    saveLead(formValues);
  };

  return (
    <Box direction={"row"} className={MoomLoaChecklistClasses.MoomLoaChecklist} >
      <Form
        subscription={{
          invalid: true,
          submitting: true,
          modified : true
        }}
        initialValues={pick(node,[
          "objectId",
          "loaCashOutReason",
          "loaVaStatement",
          "loaEstimatedAppraisedValue",
          "loaMortgageBalance",
          "loaOtherLienBalance",
          "loaCreditOrAutoLoans",
          "loaPersonalLoans",
          "loaMonthlyPayments",
          "loaElseOnBalances",
          "loaEmploymentStatus",
        ])}
        keepDirtyOnReinitialize={true}
        onSubmit={(handleSubmit)}
        layout={"inline"}
      >
        <Box className={MoomLoaChecklistClasses.ChecklistHeader} >LOA Checklist</Box>
        <Box className={MoomLoaChecklistClasses.ChecklistLogo}></Box>
        <Box className={MoomLoaChecklistClasses.ChecklistHeader}>
          Greeting
        </Box>
        <Box className={MoomLoaChecklistClasses.ChecklistRow} direction={"row"} container justify={"stretch"} alignItems={"center"} gap={"XS"}  >
          <Box flexBasis={"70%"} flexGrow={1} >
            {node.borrowerFirstName}, this is {$viewer.firstName} calling from Mutual of Omaha.  I am one of the approved lenders calling in regards to the refinance inquiry you completed online.  I see you are looking to pull cash out of the home.  Is that for debt consolidation or home improvements?
          </Box>
          <Box flexBasis={"30%"} container justify={"end"} direction={"row"} alignContent={"stretch"}>
            <Form.Select name="loaCashOutReason" data={loaCashOutReasonOptions} properties={{ searchable:false }} />
          </Box>
        </Box>
        <Box className={MoomLoaChecklistClasses.ChecklistHeader}>Set the Stage</Box>
        <Box className={MoomLoaChecklistClasses.ChecklistRow} direction={"row"} container justify={"stretch"} alignItems={"center"} gap={"XS"}  >
          <Box flexBasis={"70%"} flexGrow={1} >
            VA Statement - My file shows someone in the household is a military veteran, is that correct?
          </Box>
          <Box flexBasis={"30%"} container justify={"end"} direction={"row"} alignContent={"stretch"}>
            <Form.Select name="loaVaStatement" data={loaVaStatementOptions} properties={{ searchable:false }}  />
          </Box>
        </Box>
        <Box className={MoomLoaChecklistClasses.ChecklistRow} direction={"row"} container justify={"stretch"} alignItems={"center"} gap={"XS"} >
          <Box flexBasis={"70%"} flexGrow={0}> Fortune 300 company. A+ rated with BBB. In business since 1909. Over a century! 4.86 stars out of 5 with over 65k reviews. Wholesale lender with 38 different investors giving us access to every loan program on the market</Box>
        </Box>
        <Box className={MoomLoaChecklistClasses.ChecklistRow} direction={"row"} container justify={"stretch"} alignItems={"center"} gap={"XS"} >
          <Box flexBasis={"70%"} flexGrow={0}>Specilize in low FICO debt consolidation. Down to 500! Use an equity accelerator strategy to show you how to get out of debt faster</Box>
        </Box>
        <Box className={MoomLoaChecklistClasses.ChecklistHeader}>Property</Box>
        <Box className={MoomLoaChecklistClasses.ChecklistRow} direction={"row"} container justify={"stretch"} alignItems={"center"} gap={"XS"} >
          <Box flexBasis={"70%"} flexGrow={1} >Estimated Appraised Value?</Box>
          <Box flexBasis={"30%"} container justify={"end"} direction={"row"} >
            <Form.InputNumber name="loaEstimatedAppraisedValue"  />
          </Box>
        </Box>
        <Box className={MoomLoaChecklistClasses.ChecklistRow} direction={"row"} container justify={"stretch"} alignItems={"center"} gap={"XS"} >
          <Box flexBasis={"70%"} flexGrow={1} >Mortgage balance?</Box>
          <Box flexBasis={"30%"} container justify={"end"} direction={"row"} >
            <Form.InputNumber name="loaMortgageBalance"  />
          </Box>
        </Box>
        <Box className={MoomLoaChecklistClasses.ChecklistRow} direction={"row"} container justify={"stretch"} alignItems={"center"} gap={"XS"} >
          <Box flexBasis={"70%"} flexGrow={1} >Any 2nd mtg or other liens on the property?</Box>
          <Box flexBasis={"30%"} container justify={"end"} direction={"row"} >
            <Form.InputNumber name="loaOtherLienBalance" icon={"attach_money"}  />
          </Box>
        </Box>
        <Box className={MoomLoaChecklistClasses.ChecklistHeader}>Transition Statement</Box>
        <Box className={MoomLoaChecklistClasses.ChecklistRow} direction={"row"} container justify={"stretch"} alignItems={"center"} gap={"XS"} >
          <Box flexBasis={"70%"} flexGrow={0}>
            As you know, Lending is based on risk and one of the risk factors we have to look at is your debt to income ratio.
            When we look at your liability report, what other debts are we going to see?
          </Box>
        </Box>
        <Box className={MoomLoaChecklistClasses.ChecklistRow} direction={"row"} container justify={"stretch"} alignItems={"center"} gap={"XS"} >
          <Box flexBasis={"70%"} flexGrow={1} >Will we see any auto loans, student loans, credit card? </Box>
          <Box flexBasis={"30%"} container justify={"end"} direction={"row"} >
            <Field
              name="loaCreditOrAutoLoans"
              type="checkbox"
            >{({input, meta}) => {
              const {checked, onChange} = input;
              return <Checkbox checked={checked} onChange={(value, checked) => onChange(checked)}/>
            }}</Field>
          </Box>

        </Box>
        <Box className={MoomLoaChecklistClasses.ChecklistRow} direction={"row"} container justify={"stretch"} alignItems={"center"} gap={"XS"} >
          <Box flexBasis={"70%"} flexGrow={1} >What about personal loans or toys like a boat or RV? </Box>
          <Box flexBasis={"30%"} container justify={"end"} direction={"row"} >
            <Field
              name="loaPersonalLoans"
              type="checkbox"
            >{({input, meta}) => {
              const {checked, onChange} = input;
              return <Checkbox checked={checked} onChange={(value, checked) => onChange(checked)}/>
            }}</Field>
          </Box>
        </Box>
        <Box className={MoomLoaChecklistClasses.ChecklistHeader}>Debts</Box>
        <Box className={MoomLoaChecklistClasses.ChecklistRow} direction={"row"} container justify={"stretch"} alignItems={"center"} gap={"XS"} >
          <Box flexBasis={"70%"} flexGrow={1} >Collect balanaces and monthly payments</Box>
          <Box flexBasis={"30%"} container justify={"end"} direction={"row"} >
            <Form.InputNumber name="loaMonthlyPayments"  />
          </Box>
        </Box>
        <Box className={MoomLoaChecklistClasses.ChecklistRow} direction={"row"} container justify={"stretch"} alignItems={"center"} gap={"XS"} >
          <Box flexBasis={"70%"} flexGrow={1} >Anything else?</Box>
          <Box flexBasis={"30%"} container justify={"end"} direction={"row"} >
            <Form.Select name="loaElseOnBalances" data={loaElseOnBalancesOptions} properties={{ searchable:false }}  />
          </Box>
        </Box>
        <Box className={MoomLoaChecklistClasses.ChecklistHeader}>Income</Box>
        <Box className={MoomLoaChecklistClasses.ChecklistRow} direction={"row"} container justify={"stretch"} alignItems={"center"} gap={"XS"} >
          <Box flexBasis={"70%"} flexGrow={1} >Are you a W2 employee, self-employed, or retired?</Box>
          <Box flexBasis={"30%"} container justify={"end"} direction={"row"} >
            <Form.Select name="loaEmploymentStatus" data={loaEmploymentStatusOptions}  properties={{ searchable:false }}  />
          </Box>
        </Box>
        <Box className={MoomLoaChecklistClasses.ChecklistHeader}>
          Transfer
        </Box>
        <Box className={MoomLoaChecklistClasses.ChecklistRow} direction={"row"} container justify={"stretch"} alignItems={"center"} gap={"XS"} >
          <Box flexBasis={"70%"} flexGrow={0}>
            Thank you for sharing all of that with me. Based on this information, I believe we are going to have multiple options available for you. Give me a minute while I pull up some pricing options.
          </Box>
        </Box>
        <Box className={MoomLoaChecklistClasses.ChecklistHeader}>
          Bring LO on the Phone
        </Box>
        <Box className={MoomLoaChecklistClasses.ChecklistRow} direction={"row"} container justify={"stretch"} alignItems={"center"} gap={"XS"} >
          <Box flexBasis={"70%"} flexGrow={0}>
            {node.borrowerFirstName} I was working on this and my sales manager [LO first name] just got off another call. He wanted to jump on the line for a quick, hello.
          </Box>
        </Box>
        <FormSpy subscription={{ values: true, dirty: true, dirtyFields: true,modified : true }} onChange={handleOnChange}/>
      </Form>
      <Box className={MoomLoaChecklistClasses.ChecklistHeader}>
        Notes
      </Box>
      <Box
        title="Notes"
        className={MoomLoaChecklistClasses.ChecklistNotes}
        gap={"XXXXS"}
      >
        <Box container justify={"space-between"} alignItems={"center"}
             className={classNames(MoomLoaChecklistClasses.MoomLoaChecklistActions, {
               [ MoomLoaChecklistClasses.Active ]: !!note?.trim() && !loading
             })}>
          <Input
            placeholder={"Add note..."}
            onChange={(value) => (value?.length < 3000 || value == null) && setNote(value)}
            flex={1}
            readOnly={!canUpdate}
            value={note}
            onKeyPress={(e) => {
              if (e.key == Keys.Enter && !e.shiftKey && note?.trim() && !loading) {
                onSend();
              }
            }}
          />
          <FontIcon type={"done"} onClick={onSend}/>
        </Box>
        {data.length == 0 ?
          <Box
            container
            direction={"column"}
            alignItems={"center"}
            justify={"center"}
            className={MoomLoaChecklistClasses.MoomLoaChecklistEmptyView}
            alignSelf={"center"}
            gap={"XXS"} flex={1}
          >
            <EmptyList icon={"rc_note"} content={"Click to \"Add note...\" below to get started"} title={"No notes"}
                       alignSelf={"center"} small/>
          </Box> :
          <Box
            container
            direction={"column"}
            gap={"XS"}
            flex={1}>
            {
              data.map(({ node }, index) =>
                <Box key={index} container gap={"XXS"} className={MoomLoaChecklistClasses.MoomLoaChecklistItem}>
                  <Avatar size={AvatarSizes.Small} text={node.owner?.objectName ?? "Relcu"} icon={node.owner?.objectIcon}
                          onClick={() => node.owner && navigate(`/user/${node.owner.objectId}`)}/>
                  <Box container flex={1} direction={"column"} alignItems={"start"}>
                    <span>{DateTime.fromISO(node.createdAt).toFormat("MMM dd, h:mm a")}</span>
                    <Box container direction={"column"}>
                      <p className={MoomLoaChecklistClasses.MoomLoaChecklistItemContent}>{node.text}</p>
                    </Box>
                  </Box>
                </Box>
              )
            }
          </Box>
        }
      </Box>
    </Box>
  );
});


const GET_LAST_NOTES = gql`
  query GetLastNotes($id: ID!) {
    notes(
      last: 10,
      order: [createdAt_ASC]
      where: {
        subject: {
          have: {
            link: $id
          }
        },
        text:{
          matchesRegex: "^LOA Note:"
        }
      }) {
      pageInfo {
        hasNextPage
        hasPreviousPage
        startCursor
        endCursor
      }
      edges {
        node {
          id
          objectId
          objectIcon
          objectName
          text
          createdAt
          owner {
            id
            objectId
            objectIcon
            objectName
          }
        }
      }
    }
  }
`;
const SEND_NEW_NOTE = gql`
  mutation SendNewNote($input:CreateNoteFieldsInput!){
    createNote(input: {
      fields: $input
    }){
      clientMutationId
      note {
        id
        objectId
        objectIcon
        objectName
        text
        createdAt
        owner {
          id
          objectId
          objectIcon
          objectName
        }
      }
    }
  }
`;

const SUBSCRIBE_LAST_NOTES = gql`
  subscription SubscribeLastNotes($link: ID) {
    notes(where: {subject: {have: {link: $link}}}) {
      event
      node {
        id
        objectId
        objectIcon
        objectName
        text
        createdAt
        subject {
          ...on Document{
            objectId
            objectIcon
            objectName
          }
        }
        owner {
          id
          objectId
          objectIcon
          objectName
        }
      }
    }
  }
`;

export const UPDATE_LEAD = gql`
  mutation Update($input: UpdateLeadInput!) {
    updateLead(input: $input) {
      lead {
        id
        objectId
        loaCashOutReason
        loaVaStatement
        loaEstimatedAppraisedValue
        loaMortgageBalance
        loaOtherLienBalance
        loaCreditOrAutoLoans
        loaPersonalLoans
        loaMonthlyPayments
        loaElseOnBalances
        loaEmploymentStatus
      }
    }
  }
`;